<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.repair.closerepair.title')"
			:modelName="modelName"
			:dialogName="closeRepair"
			:onOk="onOkCloseRepair"
			:onShow="onShowCloseRepair"
			:showDialogProp="showDialogRepair"
			:widthDialog="700"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`newdetails-${modelName}`"
							v-model="model.newdetails"
							:label="$t('form.repair.newdetails')"
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`details-${modelName}`"
							v-model="model.details"
							:label="$t('form.repair.detailHistory')"
							disabled
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
		<pui-modal-dialog-form
			:titleText="$t('modal.repair.reopenrepair.title')"
			:modelName="modelName"
			:dialogName="reopenRepair"
			:onOk="onOkReopenRepair"
			:onShow="onShowReopenRepair"
			:showDialogProp="showDialogRepair"
			:widthDialog="700"
		>
			<template slot="message">
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`newdetails-${modelName}`"
							v-model="model.newdetails"
							:label="$t('form.repair.newdetails')"
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="12">
						<pui-text-area
							:id="`details-${modelName}`"
							v-model="model.details"
							:label="$t('form.repair.detailHistory')"
							disabled
							toplabel
							maxlength="1000"
						></pui-text-area>
					</v-col>
				</v-row>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
export default {
	name: 'repair-modals',
	data() {
		return {
			closeRepair: 'closeRepair',
			showDialogRepair: true,
			reopenRepair: 'reopenRepair',
			model: {},
			closedStatus: 2,
			openStatus: 1,
			newStatus: null
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		}
	},
	methods: {
		onShowCloseRepair(modalData) {
			this.model = modalData;
		},
		onOkCloseRepair() {
			this.newStatus = this.closedStatus;
			return new Promise(async (resolve) => {
				await this.updateRepair();
				resolve(true);
			});
		},
		onShowReopenRepair(modalData) {
			this.model = modalData;
		},
		onOkReopenRepair() {
			this.newStatus = this.openStatus;
			return new Promise(async (resolve) => {
				await this.updateRepair();
				resolve(true);
			});
		},
		updateRepair() {
			this.model.statusid = this.newStatus;
			this.$puiRequests.putRequest(
				'/repair/update',
				this.model,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogRepair = false;
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					this.$puiEvents.$emit(`onPui-action-running-ended-${this.modelName}`);

					this.showDialogRepair = false;
				}
			);
		}
	}
};
</script>
