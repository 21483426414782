const closeRepair = {
	id: 'closeRepair',
	selectionType: 'single',
	label: 'actions.repair.closerepair',
	functionality: 'ACTION_CLOSE_REPAIR',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries && registries[0].statusid != 2;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];

		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-closeRepair-' + model.name + '-show', row);
	}
};

const reopenRepair = {
	id: 'reopenRepair',
	selectionType: 'single',
	label: 'actions.repair.reopenrepair',
	functionality: 'ACTION_REOPEN_REPAIR',
	showInForm: true,
	checkAvailability: function (registries) {
		return registries && registries[0].statusid != 1;
	},
	runAction: function (action, model, registries) {
		const row = registries[0];
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-reopenRepair-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [closeRepair, reopenRepair],
	formactions: [closeRepair, reopenRepair]
};
