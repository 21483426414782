<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<repair-modals :modelName="modelName"></repair-modals>
	</div>
</template>

<script>
import repairActions from './RepairActions';
import repairModals from './RepairModals.vue';

export default {
	name: 'repairgrid',
	components: {
		'repair-modals': repairModals
	},
	data() {
		return {
			modelName: 'repair',
			actions: repairActions.gridactions,
			modelColumnDefs: {}
		};
	}
};
</script>
